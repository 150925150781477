<template>
  <div class="teacher">
    <list-template
        :current-page="page"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        hasAdd
        @onAdd="onAdd"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onReset="search = null"
        @onSearch="onSearch"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  components: {ListTemplate},
  data() {
    return {
      loading: true,
      // 表格配置
      tableConfig: [
        {
          prop: 'school_name',
          label: '校区',
        },
        {
          prop: 'grade_list',
          label: '适用年级 ',
          render: row => {
            return row.grade_list.join(',')
          }
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'created_at',
          label: '创建时间'
        },
        {
          label: '操作',
          handle: true,
          width: 120,
          render: row => {
            return ['编辑']
          }
        }
      ],
      searchConfig: [{
        prop: 'school_id',
        tag: 'select',
        options: [],
        placeholder: '筛选校区',
        label: 'school_name',
        value: 'id',
      }],
      search: null,
      total: 0,
      dialogVisible: false,
      // 表格中的数据
      tableData: []
    }
  },
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.$_axios.get('site/school').then(res => {
      this.searchConfig[0].options = res.data.data
    })
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get('api/coach-task/cycle-list', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => this.loading = false);
    },
    // 新增按钮
    onAdd() {
      this.$router.push('./add')
    },
    // 表格后面操作被点击
    tableHandle(row, handle, index) {
      this.$router.push({path: './edit', query: {id: row.id}})
    },
    // 搜索
    onSearch(val) {
      this.search = val;
      this.$store.commit("setPage", 1);
      this.getData(val)
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }
}
</style>
